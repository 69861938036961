<template>
  <div>
    <el-row style="margin-top: 20px">
      <el-form :inline="true" :model="queryForm">
        <el-form-item label="查询" class="formItemBoxStyle">
          <el-input
            v-model="queryForm.query"
            placeholder="请输入"
            @input="getAiMarkingListApiFunc(queryForm.query)"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" icon="el-icon-refresh" @click="refresh"
            >刷新</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
    <!-- 表单 -->
    <el-row>
      <el-table
        v-loading="loading"
        header-align="center"
        border
        :header-cell-style="{ background: '#DFE6EC' }"
        :data="tableData"
        stripe
        style="width: 100%"
      >
        <el-table-column
          align="center"
          prop="experimentUuid"
          label="实验记录唯一编码"
          min-width="300"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="experimentCode"
          label="实验编码"
          min-width="180"
        >
          <template slot-scope="scope">{{ scope.row.experimentCode }}</template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="experimentType"
          label="实验类型"
          width="100"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.experimentType === 0" style="color: #f36b6b">
              考试
            </div>
            <div v-if="scope.row.experimentType === 1" style="color: #3f9dfd">
              自测
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="markStatus"
          label="评分状态"
          width="100"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.markStatus === 1">正在评分</div>
            <div v-if="scope.row.markStatus === 4">完成评分</div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="name"
          label="实验名称"
          min-width="340"
        >
          <template slot-scope="scope">{{ scope.row.name }}</template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="version"
          label="AI评分版本"
          width="100"
        >
          <template slot-scope="scope">{{ scope.row.version }}</template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="aiResultTime"
          label="评分提交时间"
          show-overflow-tooltip
          width="200px"
          ><template slot-scope="scope">{{
            scope.row.aiResultTime
              ? customFormatter(scope.row.aiResultTime)
              : ''
          }}</template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="240">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="viewBtn(scope.row.experimentUuid)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="tableDataPagination.pageIndex"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="tableDataPagination.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableDataPagination.total"
      ></el-pagination> -->
    </el-row>
  </div>
</template>

<script>
import { getAiMarkingListApi } from '@/api/marking/ai.js'
import { formatDate } from '@/utils/util'
export default {
  name: 'scoring',
  data() {
    return {
      loading:false,
      queryForm: {
        query: '',
      },
      tableData: [],
      // 分页
      tableDataPagination: {
        // 当前页
        pageIndex: 1,
        // 页大小
        pageSize: 5,
        // 页数
        pageTotal: 1,
        // 总数量
        total: 0,
      },
    }
  },
  mounted() {
    this.getAiMarkingListApiFunc()
  },
  methods: {
    getAiMarkingListApiFunc(query) {
      this.loading = true
      if (query == undefined) {
        query = ''
      }
      let formData = new FormData()
      formData.append('query', query)
      getAiMarkingListApi(formData)
        .then((res) => {
          this.loading = false
          console.log('res', res)
          if (res.success) {
            this.tableData = res.data
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    viewBtn(experimentUuid) {
      console.log(experimentUuid)
      this.$router.push({
        path: `/scoreAI/detailScoring?experimentUuid=${experimentUuid}`,
      })
    },
    customFormatter(timestamp) {
      return formatDate(new Date(timestamp), 'yyyy-MM-dd hh:mm:ss')
    },
    refresh() {
      this.queryForm.query = ''
      this.getAiMarkingListApiFunc(this.queryForm.query)
    },
    // // console.log(`每页 ${val} 条`);
    // handleSizeChange(val) {
    //   this.tableDataPagination.pageSize = val
    //   this.sendAsk()
    // },
    // // console.log(`当前页: ${val}`);
    // handleCurrentChange(val) {
    //   this.tableDataPagination.pageIndex = val
    //   this.sendAsk()
    // },
  },
}
</script>

<style></style>
